export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "cs": {
        "helpTranslate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nápověda k překladu Central"])}
      },
      "de": {
        "helpTranslate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilf Central zu übersetzen"])}
      },
      "es": {
        "helpTranslate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayuda a traducir Central"])}
      },
      "fr": {
        "helpTranslate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aider à traduire Central"])}
      },
      "id": {
        "helpTranslate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bantu terjemahan pusat"])}
      },
      "it": {
        "helpTranslate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiuta a tradurre Central"])}
      },
      "ja": {
        "helpTranslate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centralの翻訳に貢献する"])}
      },
      "sw": {
        "helpTranslate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saidia kutafsiri Central"])}
      }
    }
  })
}
