export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A user is already logged in. Please refresh the page to continue."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password is shorter than 10 characters. To protect your account, please change your password to make it longer."])}
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect email address and/or password."])}
        }
      }
    }
  })
}
