export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
        "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])}
      }
    }
  })
}
