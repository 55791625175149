export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "cs": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uživatel je již přihlášen. Chcete-li pokračovat, obnovte stránku."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše heslo je kratší než 10 znaků. Chcete-li svůj účet chránit, změňte si heslo tak, aby bylo delší."])}
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávná e-mailová adresa nebo heslo."])}
        }
      },
      "de": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Benutzer ist bereits eingeloggt. Bitte die Seite aktualisieren um weiterzuarbeiten."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort ist kürzer als 10 Zeichen. Um Ihr Konto zu schützen, verlängerns Sie bitte Ihr Passwort."])}
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche E-Mail-Adresse und/oder Passwort."])}
        }
      },
      "es": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un usuario ya ha iniciado sesión. Actualice la página para continuar."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su contraseña tiene menos de 10 caracteres. Para proteger su cuenta, cambie su contraseña para que sea más larga."])}
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de correo electrónico y/o contraseña incorrecta."])}
        }
      },
      "fr": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un utilisateur est déjà connecté. Merci de rafraîchir la page pour continuer."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe fait moins de 10 caractères. Pour protéger votre compte, merci de choisir un mot de passe plus long."])}
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de courriel et/ou mot de passe invalides."])}
        }
      },
      "id": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seorang pengguna sudah masuk. Mohon perbarui halaman untuk melanjutkan."])}
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat email dan/atau kata sandi salah."])}
        }
      },
      "it": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un utente ha già effettuato l'accesso. Aggiorna la pagina per continuare."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua password è lunga meno di 10 caratteri. Per proteggere il tuo account, cambia la password per renderla più lunga."])}
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo e-mail e/o password errati."])}
        }
      },
      "ja": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すでにユーザーでログインされています。 続けるにはページを更新してください。"])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのパスワードは10文字以下です。アカウントを守るため、パスワードを長いものに変更してください。"])}
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスとパスワードの一方、または両方が違います。"])}
        }
      },
      "sw": {
        "alert": {
          "alreadyLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mtumiaji tayari ameingia. Tafadhali onyesha upya ukurasa ili kuendelea."])},
          "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenosiri lako ni fupi kuliko vibambo 10. Ili kulinda akaunti yako, tafadhali badilisha nenosiri lako ili kuifanya iwe ndefu."])}
        },
        "problem": {
          "401_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwani ya barua pepe na/au nenosiri si sahihi."])}
        }
      }
    }
  })
}
