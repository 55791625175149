export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "cs": {
        "alert": {
          "loadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Požadovanou stránku se nepodařilo načíst. Obnovte prosím stránku a zkuste to znovu."])}
        }
      },
      "de": {
        "alert": {
          "loadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Seite konnte nicht geladen werden. Bitte aktualisiere die Seite und versuche es erneut."])}
        }
      },
      "es": {
        "alert": {
          "loadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cargar la página solicitada. Actualice la página y vuelva a intentarlo."])}
        }
      },
      "fr": {
        "alert": {
          "loadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La page demandée ne peut être chargée. Merci de rafraîchir la page et d'essayer à nouveau."])}
        }
      },
      "it": {
        "alert": {
          "loadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossibile caricare la pagina richiesta. Perfavore ricarica la pagina e riprova."])}
        }
      },
      "ja": {
        "alert": {
          "loadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リクエストされたページを読み込むことができませんでした。ページを更新して、もう一度試みて下さい。"])}
        }
      },
      "sw": {
        "alert": {
          "loadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ukurasa ulioomba haukuweza kupakiwa. Tafadhali onyesha upya ukurasa na ujaribu tena"])}
        }
      }
    }
  })
}
