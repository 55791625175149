export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "alert": {
          "loadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you requested could not be loaded. Please refresh the page and try again."])}
        }
      }
    }
  })
}
