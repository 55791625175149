export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "cs": {
        "action": {
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přepnout navigaci"])}
        },
        "analyticsNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomozte zlepšit Central!"])}
      },
      "de": {
        "action": {
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation umschalten"])}
        },
        "analyticsNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilf Central zu verbessern!"])}
      },
      "es": {
        "action": {
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alternar la navegación"])}
        },
        "analyticsNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayuda a mejorar Central"])}
      },
      "fr": {
        "action": {
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basculer la navigation"])}
        },
        "analyticsNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aidez à améliorer Central !"])}
      },
      "id": {
        "action": {
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigasi Toggle"])}
        },
        "analyticsNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bantu Memperbaiki Central!"])}
      },
      "it": {
        "action": {
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva/disattiva navigazione"])}
        },
        "analyticsNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiuta a migliorare Central"])}
      },
      "ja": {
        "action": {
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ナビゲーションを有効化"])}
        },
        "analyticsNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centralの改善を支援！"])}
      },
      "sw": {
        "action": {
          "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geuza urambazaji"])}
        },
        "analyticsNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saidia kuboresha Central"])}
      }
    }
  })
}
