export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "cs": {
        "notLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepřihlášen"])},
        "action": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásit se"])}
        },
        "alert": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspěšně jste se odhlásili."])}
        }
      },
      "de": {
        "notLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht angemeldet"])},
        "action": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausloggen"])}
        },
        "alert": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben erfolgreich ausgeloggt."])}
        }
      },
      "es": {
        "notLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No ha iniciado sesión"])},
        "action": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])}
        },
        "alert": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha cerrado la sesión correctamente."])}
        }
      },
      "fr": {
        "notLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non connecté"])},
        "action": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])}
        },
        "alert": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez été déconnecté avec succès."])}
        }
      },
      "id": {
        "notLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belum masuk"])},
        "action": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keluar"])}
        },
        "alert": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda sudah berhasil keluar."])}
        }
      },
      "it": {
        "notLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso non effettuato"])},
        "action": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnettersi"])}
        },
        "alert": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti sei disconnesso con successo."])}
        }
      },
      "ja": {
        "notLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
        "action": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウト"])}
        },
        "alert": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウトに成功しました。"])}
        }
      },
      "sw": {
        "notLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hujaingia"])},
        "action": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toka kwenye ukurasa"])}
        },
        "alert": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umetoka kwenye ukurasa kwa mafanikio."])}
        }
      }
    }
  })
}
