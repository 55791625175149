export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "notLoggedIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not logged in"])},
        "action": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])}
        },
        "alert": {
          "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have logged out successfully."])}
        }
      }
    }
  })
}
